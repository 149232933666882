
.main {
  margin: 2rem 8rem;
}

.policies {
  margin: 2rem;
}

.add-policy {
  margin: 2rem;
}
.add-policy small {
  color: red;
  text-align: right;
}

.loading {
  margin: 0 auto;
  width: 0;
}